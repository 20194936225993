// log
import store from "../store";

const fetchDataBaseRequest = () => {
  return {
    type: "CHECK_DATABASE_REQUEST",
  };
};

const fetchDataBaseSuccess = (payload) => {
  return {
    type: "CHECK_DATABASE_SUCCESS",
    payload: payload,
  };
};

const fetchDataBaseFailed = (payload) => {
  return {
    type: "CHECK_DATABASE_FAILED",
    payload: payload,
  };
};

export const fetchDataBase = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataBaseRequest());
    try {
      let imageURI = [];
      let tokenId = [];
      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwnerBase(account)
        .call();
      tokenId = walletOfOwner;

      for (let i = 0; i < walletOfOwner.length; i++) {
        imageURI[tokenId[i]] = [
          i,
          // "https://startdata.io/NSK/" + String(tokenId[i]) + ".png?r=1",
          // "https://startdata.io/CNW/" + String(tokenId[i]) + ".png?r=1",
          "https://startdata.io/PNL/" + String(tokenId[i]) + ".png?r=1",
          "#" + String(tokenId[i]),
          tokenId[i],
        ];
      }

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataBaseSuccess({
          imageURI,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataBaseFailed("Could not load data from contract."));
    }
  };
};
