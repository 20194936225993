const initialState = {
  loading: false,
  burnWriteEnable: false,
  burnWriteQEnable: false,
  burnWriteRemoveEnable: false,
  requests: [],
  walletOfOwner: [],
  error: false,
  errorMsg: "",
};

const dataBurnReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATABURN_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATABURN_SUCCESS":
      return {
        ...state,
        loading: false,
        burnWriteEnable: action.payload.burnWriteEnable,
        burnWriteRemoveEnable: action.payload.burnWriteRemoveEnable,
        requests: action.payload.requests,
        walletOfOwner: action.payload.walletOfOwner,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATABURN_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataBurnReducer;
