// log
import store from "../store";

const fetchDataBurnRequest = () => {
  return {
    type: "CHECK_DATABURN_REQUEST",
  };
};

const fetchDataBurnSuccess = (payload) => {
  return {
    type: "CHECK_DATABURN_SUCCESS",
    payload: payload,
  };
};

const fetchDataBurnFailed = (payload) => {
  return {
    type: "CHECK_DATABURN_FAILED",
    payload: payload,
  };
};

export const fetchDataBurn = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataBurnRequest());
    try {
      // let burnWriteEnable = await store
      //   .getState()
      //   .blockchain.smartContract.methods.burnWriteEnable()
      //   .call();
      // let burnWriteRemoveEnable = await store
      //   .getState()
      //   .blockchain.smartContract.methods.burnWriteRemoveEnable()
      //   .call();
      let burnWriteEnable = true;
      let burnWriteRemoveEnable = true;
      let requests = await store
        .getState()
        .blockchain.smartContract.methods._getRequest()
        .call();
      var removals = ["4874"];
      requests = requests.filter(function (v) {
        return !removals.includes(v);
      });
      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(account)
        .call();

      // let walletOfOwner = await store
      //   .getState()
      //   .blockchain.smartContract.methods.walletOfOwner(account)
      //   .call();

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataBurnSuccess({
          burnWriteEnable,
          burnWriteRemoveEnable,
          requests,
          walletOfOwner,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataBurnFailed("Could not load data from contract."));
    }
  };
};
